<template>
<div>
  <div class="row justify-content-center my-5" id="buttons">
    <b-button-toolbar key-nav aria-label="Opciones">
      <b-button-group class="mx-1">
        <b-button variant="secondary" @click="printReport()">
          <b-icon icon="printer-fill"/> Imprimir
        </b-button>
        <b-button variant="primary" :to="{name:'home'}">
          <b-icon icon="house-door-fill"/> Inicio
        </b-button>
        <b-button variant="light" @click="goBack()">
          <b-icon icon="arrow-return-left"/> Regresar
        </b-button>
      </b-button-group>
    </b-button-toolbar>
  </div>
  <b-container id="print" v-if="ready">
    <b-table-simple hover small striped stacked>
      <b-tbody>
        <b-tr>
          <b-th class="text-white text-center bg-dark">Código de Muestra: {{testDetail.code}}</b-th>
        </b-tr>
        <b-table-simple hover small striped stacked caption-top bordered>
          <b-tbody>
            <b-tr>
                <b-td stacked-heading="Código de OT" class="text-center">
                    {{testDetail.receipt_samples.code}}
                </b-td>
                <b-td stacked-heading="Código QR" class="text-center">
                    {{testDetail.code}}
                </b-td>
                <b-td stacked-heading="Material de muestra" class="text-center">
                    {{testDetail.receipt_samples.sample_material}}
                </b-td>
                <b-td stacked-heading="Tipo de ingreso" class="text-center">
                    {{testDetail.receipt_samples.sample_type}}
                </b-td>
            </b-tr>
            <b-tr class="text-white text-center" style="margin-top:15px">
              <qrcode-vue :value="testDetail.code" :size="410" level="H" />
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-tbody>
    </b-table-simple>
  </b-container>
</div>
</template>

<script>
import { fractalMixin } from '@/mixins/fractalMixin'
import QrcodeVue from 'qrcode.vue'
export default {
  name: 'testWarehouseQR',
  mixins: [fractalMixin],
  components: { QrcodeVue },
  data() {
    return {
      id: this.$route.params.id,
      testDetail: {},
      tableObject: {
        data: [],
        pagination: {
          current: 1,
          pageSize: 15,
          from: 1,
          to: 1,
          total: 1,
          lastPage: 1,
        },
        loading: false,
        columns: [],
      },
    }
  },
  computed: {
    ready() {
      return Object.keys(this.testDetail).length > 0
    },
  },
  methods: {
    printReport() {
      window.print()
    },
    goBack() {
      this.$router.go(-1)
    },
    async retrieveTestWarehouse(pagEvent = {}) {
      await this.GetResourcev2(`sampleStorageAllocations/${this.id}`)
        .then(response => {
          this.testDetail = response.data
        })
      // // console.log('Valor filtro: ', this.filterEstatus)
    },
  },
  async mounted() {
    await this.retrieveTestWarehouse()
  },
}
</script>

<style scoped>
  @media print {
    /* All your print styles go here */
    #buttons { display: none !important; }
  }
</style>
